





















































































import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import { Form } from '@/includes/types/Form.types'

@Component({
  data() {
    return {
      BaseItemViewAction,
    }
  }
})
export default class FormsItemsList extends Vue {
  @Prop() forms!: Array<Required<Form>>

  @Prop() gotoForm!: (actionType: BaseItemViewAction, formKey?: string) =>  void

  @Emit('handleTagClick')
  handleTagClick(form: Required<Form>): Required<Form> {
    return form
  }

  @Emit('showPreview')
  showPreview(form: Required<Form>): Required<Form> {
    return form
  }

  cutIfLarge(str:string):string {
    if (str.length > 150) {
      return str.slice(0, 150) + "..."
    }

    return str
  }

  deleteFormWarn(key:string):void {
    this.$confirm({
      title: this.$t('delete_form_warn_title').toString(),
      content: this.$t('delete_form_warn_text').toString(),
      okText: this.$t('confirm').toString(),
      cancelText: this.$t('cancel').toString(),
      onOk: () => this.deleteForm(key)
    })
  }

  deleteForm(key:string):void {
    this.$store.dispatch('deleteForm', key)
  }
}
