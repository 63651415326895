var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-form-field"},[_c('form-field-wrapper',{attrs:{"value":_vm.fieldValue,"config":_vm.config}},[(_vm.config.type === _vm.FormFieldType.Number)?_c('number-input',{staticClass:"fill-form-field__field",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'fieldValue',
          'titleVisible': false,
          'inline': false,
          'disabled': _vm.disabled,
          'step': 1,
          'formatter': function (val) { return Math.floor(val); },
        }
      }}}):_vm._e(),(_vm.config.type === _vm.FormFieldType.String)?[_c('text-input',{staticClass:"fill-form-field__field",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': this,
            'key': 'fieldValue',
            'titleVisible': false,
            'placeholder': _vm.config.placeholder,
            'disabled': _vm.disabled,
            'maxLength': _vm.config.max === 0 ? Number.MAX_SAFE_INTEGER : _vm.config.max
          }
        }}}),(_vm.config.validator)?[(!_vm.patternCheck)?_c('span',{staticClass:"mt-2 text-danger text-xs"},[_vm._v(" "+_vm._s(_vm.config.validator.message)+" ")]):_vm._e()]:_vm._e()]:_vm._e(),(_vm.config.type === _vm.FormFieldType.Double)?_c('number-input',{staticClass:"fill-form-field__field",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'fieldValue',
          'titleVisible': false,
          'step': 0.1,
          'inline': false,
          'disabled': _vm.disabled
        }
      }}}):_vm._e(),(_vm.config.type === _vm.FormFieldType.DateTime)?_c('date-picker-input',{key:"date-time",staticClass:"fill-form-field__field",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'fieldValue',
          'titleVisible': false,
          'placeholder': _vm.config.placeholder,
          'format': _vm.PickerFormat.DateTime,
          'disabled': _vm.disabled
        }
      }}}):_vm._e(),(_vm.config.type === _vm.FormFieldType.Date)?_c('date-picker-input',{key:"date",staticClass:"fill-form-field__field",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'fieldValue',
          'titleVisible': false,
          'placeholder': _vm.config.placeholder,
          'format': _vm.PickerFormat.Date,
          'disabled': _vm.disabled
        }
      }}}):_vm._e(),(_vm.config.type === _vm.FormFieldType.Select)?_c('div',{staticClass:"mx-2 mt-2"},[_c('v-select',{attrs:{"options":_vm.fieldConfigOptions,"multiple":false,"push-tags":false,"taggable":false,"close-on-select":true,"clearable":!_vm.config.required,"disabled":_vm.disabled,"placeholder":_vm.config.placeholder},model:{value:(_vm.selectValue),callback:function ($$v) {_vm.selectValue=$$v},expression:"selectValue"}})],1):_vm._e(),(_vm.config.type === _vm.FormFieldType.Multiselect)?_c('select-input',{staticClass:"fill-form-field__field",attrs:{"setup":{
        'func': _vm.setValueIfInLimit,
        'args': {
          'model': this,
          'key': 'fieldValue',
          'placeholder': _vm.config.placeholder,
          'options': _vm.fieldConfigOptions,
          'titleVisible': false,
          'multiple': true,
          'disabled': _vm.disabled,
        }
      }}}):_vm._e(),(_vm.config.type === _vm.FormFieldType.Files)?[_c('files-list-row-scroll',{attrs:{"files-data":_vm.localFiles},on:{"removeFile":_vm.removeFile}}),_c('media-uploader',{attrs:{"base-api-url":"api.presscode.app/cabinet","editor-mode":_vm.MediaEditorMode.TelegramAll,"disabled":_vm.config.max === 0 ? false : _vm.localFiles.length === _vm.config.max,"all-document":_vm.config.file_type === _vm.FormFileType.Document,"files-to-upload-limit":_vm.config.max === 0 ? Number.MAX_SAFE_INTEGER : _vm.config.max,"target-to-upload":_vm.targetToUpload},on:{"fileLoaded":_vm.handleUpload},model:{value:(_vm.localFiles),callback:function ($$v) {_vm.localFiles=$$v},expression:"localFiles"}})]:_vm._e(),(_vm.config.type === _vm.FormFieldType.Flag)?_c('switch-input',{staticClass:"fill-form-field__field",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'fieldValue',
          'titleVisible': false,
        },
      }}}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }