var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-list"},[_c('page-title',[_c('a-button',{attrs:{"slot":"custom-button","type":"primary"},on:{"click":function($event){return _vm.gotoForm(_vm.BaseItemViewAction.New)}},slot:"custom-button"},[_vm._v(" "+_vm._s(_vm.$t('goto_form_create_button_title'))+" ")])],1),_c('a-drawer',{staticClass:"form-preview-modal sidebar",attrs:{"visible":_vm.sidebarIsOpen,"mask-closable":true,"closable":true,"title":_vm.sidebarTitle,"destroy-on-close":"","width":_vm.drawerWidth,"body-style":{ paddingBottom: '80px' }},on:{"close":_vm.handleDrawerClose}},[_c('transition-group',{attrs:{"name":"default-hide","mode":"out-in"}},[(_vm.selectedForm && _vm.sidebarType === _vm.SidebarType.Answers)?_c('form-answers',{key:"answers",attrs:{"form":_vm.selectedForm}}):_vm._e(),(_vm.selectedForm && _vm.sidebarType === _vm.SidebarType.Preview)?_c('form-preview',{key:"preview",attrs:{"form":_vm.selectedForm}}):_vm._e()],1)],1),_c('div',{staticClass:"flex xs:items-end flex-col xs:flex-row"},[_c('div',{staticClass:"flex-1"},[_c('a-divider',{attrs:{"orientation":"left"}},[_c('span',{staticClass:"text-2xl"},[_vm._v(" "+_vm._s(_vm.$t('forms_list_active_forms'))+" ")])])],1),(_vm.activeForms.length)?_c('select-input',{staticClass:"mt-0",staticStyle:{"min-width":"200px"},attrs:{"setup":{
        'func': _vm.setSortBy,
        'args': {
          'model': this,
          'key': 'sortBy',
          'prefix': 'forms_',
          'options': [
            { label: _vm.$t('forms_sort_by_date').toString(), value: _vm.SortBy.Date },
            { label: _vm.$t('forms_sort_by_answers').toString(), value: _vm.SortBy.Answers }
          ],
          'clearable': false,
        }
      }}}):_vm._e()],1),(_vm.activeForms.length)?[_c('a-alert',{attrs:{"type":"info","message":_vm.$t('active_forms_mini_help_message')}}),_c('forms-items-list',{staticClass:"mt-2",attrs:{"forms":_vm.activeForms,"goto-form":_vm.gotoForm},on:{"handleTagClick":_vm.handleTagClick,"showPreview":_vm.showPreview}})]:_c('empty-data'),_c('a-divider',{attrs:{"orientation":"left"}},[_c('span',{staticClass:"text-2xl"},[_vm._v(" "+_vm._s(_vm.$t('forms_list_not_active_forms'))+" ")])]),(_vm.notActiveForms.length)?_c('forms-items-list',{attrs:{"forms":_vm.notActiveForms,"goto-form":_vm.gotoForm},on:{"handleTagClick":_vm.handleTagClick,"showPreview":_vm.showPreview}}):_c('empty-data')],2)}
var staticRenderFns = []

export { render, staticRenderFns }