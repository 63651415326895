





























import { FormService } from '@/includes/services/FormService'
import { Form, FormAnswer, FormFieldType } from '@/includes/types/Form.types'
import { errorNotification } from '@/includes/services/NotificationService'
import FormAnswerCard from '@/components/Forms/components/FormAnswerCard.vue'

import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import ZoomableImage from 'piramis-base-components/src/components/ZoomableImage/ZoomableImage.vue'

import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    FormAnswerCard,
    UserMainInfo,
    ZoomableImage
  },
  data() {
    return {
      FormFieldType
    }
  }
})
export default class FormAnswers extends Vue {
  @Prop({ type:Object }) form!:Required<Form>

  limit = 10

  offset = 0

  answers: Array<FormAnswer> = []

  loading = false

  answersEnd = false

  loadMore():void {
    this.requestAnswers()
  }

  requestAnswers():void {
    this.loading = true

    FormService.getFormAnswers('tg', {
      board_key: this.$store.state.boardsState.activeBoard!.board,
      key: this.form.key,
      limit: this.limit,
      offset: this.answers.length ? this.answers.length : 0,
    })
      .then(({ items }) => {
        this.answers.push(...items)
        if (items.length < 10) {
          this.answersEnd = true
        }
      })
      .catch(errorNotification)
      .finally(() => {
        this.loading = false
      })
  }

  mounted():void {
    this.requestAnswers()
  }
}
