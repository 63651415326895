















































import FillFormField from '@/components/Forms/components/FillFormField.vue'
import { FrontendFormField } from '@/includes/types/Form.types'

import { Component, Prop, VModel } from 'vue-property-decorator'
import Vue from 'vue'
import { IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";

@Component({
  components:{
    FillFormField
  }
})
export default class FullForm extends Vue {
  @VModel() formFields!:Array<IterableListItem<FrontendFormField>>

  @Prop() name!:string

  @Prop() description!:string

  @Prop() targetToUpload!:any
}
