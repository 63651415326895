export type Form = {
    name: string
    description: string
    disposable: boolean
    created: string
    updated: string
    config: FormConfig
    answers?: number
    key?: string
}

export type FormSuccessMessage = {
    text?: ''
    attachments?: Array<string>
}

export type FormConfig = {
    fields: Array<FormFields>
    success_message?: FormSuccessMessage
}

export enum FormFieldType {
    Number = 'Number',
    DateTime = 'DateTime',
    Date = 'Date',
    Double = 'Double',
    String = 'String',
    Multiselect = 'Multiselect',
    Select = 'Select',
    Files = 'Files',
    Flag = 'Flag'
}

export type FrontendFormField = {
    config: FormFields
    model: Array<string> | number | string | null | boolean
}

export type BaseFieldData = {
    id?:number
    name: string
    description: string
    required: boolean
    property_id: number | null
}

type FieldPlaceholder = { placeholder: string | null }

export type FormFields = FormFlagField | FormNumberField | FormStringField | FormDoubleField | FormDateTimeField | FormDateField | FormMultiselectField | FormSelectField | FormFilesField

export type FormNumberField = {
    type: FormFieldType.Number
} & BaseFieldData

export type FormFlagField = {
    type: FormFieldType.Flag
} & BaseFieldData

export type StringValidator = {
    pattern: string
    negate: boolean
    message: string
}

export type FormStringField = {
    type: FormFieldType.String
    validator?: StringValidator | null
    limit?: number
} & BaseFieldData & FieldPlaceholder & MinMaxFields

export type FormDoubleField = {
    type: FormFieldType.Double
} & BaseFieldData

export type FormDateTimeField = {
    type: FormFieldType.DateTime
} & BaseFieldData & FieldPlaceholder

export type FormDateField = {
    type: FormFieldType.Date
} & BaseFieldData & FieldPlaceholder

export type FormMultiselectField = {
    type: FormFieldType.Multiselect
    options: Array<any>
    limit?: number
} & BaseFieldData & FieldPlaceholder & MinMaxFields

export type FormSelectField = {
    type: FormFieldType.Select
    options: Array<any>
} & BaseFieldData & FieldPlaceholder

export type FormFilesField = {
    type: FormFieldType.Files
    file_type: FormFileType
    limit?: number
} & BaseFieldData & MinMaxFields

export type MinMaxFields = {
    min?: number
    max?: number
}

export enum FormFileType {
    Media = 'Media',
    Document = 'Document'
}

export type FormAnswerFields = {
    values: Record<string, any>
}

export type FormAnswerRequest = {
    token:string
    answer: FormAnswerFields
}

export type TestFormRequest = Pick<Form, 'name' | 'config'> & Pick<FormAnswerRequest, 'answer'> & { board_key:string }

export type FormAnswersRequest = {
    board_key: string
    key: string
    offset: number
    limit: number
}

export type AnsweredUser = {
    userId: number
    type: number
    userType: number
    login?: string
    name: string
    languageCode: string
    createdAt: string
    updatedAt: string
    avatar_url: string
}

export type FormAnswer = {
    id:number
    date: string
    user: AnsweredUser
    data: FormAnswerFields
}

export type ILastFormAnswer = {
    form?: Required<Form>
    answer?: {
        id: number
        user: AnsweredUser
        date: string
        data: FormAnswerFields
    }
    board_key?: string
}