import {
    BaseFieldData,
    FormAnswerFields, FormConfig,
    FormFields,
    FormFieldType, FormFileType,
    FrontendFormField,
} from '@/includes/types/Form.types'
import i18n from '@/i18n/i18n'

import { IterableItemFactory, IterableListItem } from 'piramis-base-components/src/shared/utils/IterableItemFactory'

import { cloneDeep } from 'lodash'

type FieldsStructMap = Record<FormFieldType, FrontendFormField>

const iterableItemFactory = new IterableItemFactory()

const baseStruct:BaseFieldData = {
    name: '',
    description: '',
    required: true,
    property_id: null,
}

export function getFieldTemplate() {
    return iterableItemFactory.create(cloneDeep(formFieldsModel[FormFieldType.String]))
}

export function parseSuccessMessage(form: FormConfig) {
    if (form.success_message) {
        if ((!form.success_message.text?.length || form.success_message.text as string === "\n" )&& !form.success_message.attachments?.length) {
            delete form.success_message
        }
    }

    return form
}

export function prepareFrontendFields(fields: Array<FormFields>): Array<IterableListItem<FrontendFormField>> {
    return fields.map(f => {
        const { id, ...fields } = f

        return {
            config: fields,
            model: formFieldsModel[f.type].model
        }
    })
        .map(iterableItemFactory.create)
}

export function prepareFormForCreate(fields: Array<IterableListItem<FrontendFormField>>): Array<Required<BaseFieldData>> {
    return fields.map((field, index) => ({
        id: index + 1,
        ...field.value.config
    }))
}

export function prepareFormForAnswer(fields: Array<IterableListItem<FrontendFormField>>): FormAnswerFields['values'] {
    const resObj = {}

    fields.forEach((field, index) => {
        const fieldModel = field.value.model
        const fieldConfig = field.value.config
        const fieldWithMinMaxValidation = fieldConfig.type === FormFieldType.String || fieldConfig.type === FormFieldType.Multiselect || fieldConfig.type === FormFieldType.Files

        if (fieldConfig.required) {
            if (fieldModel === null || fieldModel === '' || (fieldModel as Array<string> | string).length === 0) {
                throw i18n.t('field_form_required_error', [ fieldConfig.name ]);
            } else if (fieldWithMinMaxValidation && fieldConfig.min !== 0 && fieldConfig.min && (fieldModel as Array<string> | string).length < fieldConfig.min) {
                throw i18n.t('field_form_min_values', [ fieldConfig.name ])
            } else {
                resObj[index + 1] = fieldModel
            }
        } else {
            resObj[index + 1] = fieldModel
        }
    })

    return resObj
}

export const formFieldsModel: FieldsStructMap = {
    [FormFieldType.String]: {
        config: {
            type: FormFieldType.String,
            ...baseStruct,
            placeholder: '',
            validator: null,
            min: 0,
            max: 0,
        },
        model: ''
    },
    [FormFieldType.Number]: {
        config: {
            type: FormFieldType.Number,
            ...baseStruct,
        },
        model: 0
    },
    [FormFieldType.Double]: {
        config: {
            type: FormFieldType.Double,
            ...baseStruct,
        },
        model: 0.0
    },
    [FormFieldType.Date]: {
        config: {
            type: FormFieldType.Date,
            ...baseStruct,
            placeholder: ''
        },
        model: null
    },
    [FormFieldType.DateTime]: {
        config: {
            type: FormFieldType.DateTime,
            ...baseStruct,
            placeholder: ''
        },
        model: null
    },
    [FormFieldType.Multiselect]: {
        config: {
            type: FormFieldType.Multiselect,
            ...baseStruct,
            placeholder: '',
            options: [],
            min: 0,
            max: 0,
            limit: 0
        },
        model: []
    },
    [FormFieldType.Select]: {
        config: {
            type: FormFieldType.Select,
            ...baseStruct,
            placeholder: '',
            options: [],
        },
        model: 0
    },
    [FormFieldType.Files]: {
        config: {
            type: FormFieldType.Files,
            file_type: FormFileType.Media,
            ...baseStruct,
            min: 0,
            max: 2,
        },
        model: []
    },
    [FormFieldType.Flag]: {
        config: {
            type: FormFieldType.Flag,
            ...baseStruct,
        },
        model: false
    }
}
