









































































type AnswerResult = {
  name: string
  value: any
  type: FormFieldType
}

import { AnsweredUser, Form, FormAnswerFields, FormFields, FormFieldType } from '@/includes/types/Form.types'
import GetFilesUrlService from '@/includes/services/GetFilesUrlService'

import FileApi from 'piramis-base-components/src/components/File/logic/file-api'
import ZoomableImage from 'piramis-base-components/src/components/ZoomableImage/ZoomableImage.vue'
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue'
import { tgLoginLink } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'

import moment from 'moment'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    UserMainInfo,
    ZoomableImage
  },
  data() {
    return {
      FormFieldType
    }
  }
})
export default class FormAnswerCard extends Vue {
  @Prop() fileTarget!: string

  @Prop() form!: Required<Form>

  @Prop() user!: AnsweredUser

  @Prop() formFields!: FormAnswerFields

  @Prop() answerDate!: string

  fileApi = new FileApi('api.presscode.app/cabinet')

  getSaveUrl(descriptor:string):void {
    GetFilesUrlService.getFilesUrl('tg', descriptor, {
      'target': this.fileTarget
    })
  }

  filePreview(descriptor:string): string {
    return `${ this.fileApi.getApiUrl() }/getfilepreview?file_descriptor=${ descriptor }&target=${ this.fileTarget }`
  }

  telegramLink(userLogin:string):string  {
    return tgLoginLink(userLogin)
  }

  get formattedDate():string {
    return moment(this.answerDate).format('LLL')
  }

  getAnswerValue(answer, field: FormFields) {
    if (field.id) {
      const value = answer[field.id]

      if (field.type === FormFieldType.Multiselect) {
        const readableAnswers = value.map(i => field.options[i])

        if (readableAnswers.length > 0) {
          return readableAnswers.length === 1 ? readableAnswers[0] : readableAnswers.join(', ')
        }

        return '-'
      } else if (field.type === FormFieldType.Flag) {
        return this.$t(`form_answer_field_flag_value_${ value }`)
      }

      if (field.type === FormFieldType.Select) {
        return field.options[value] ?? '-'
      }

      return value
    }
  }

  parseAnswerDataFields(answers: FormAnswerFields):Array<AnswerResult> {
    const fields:Array<AnswerResult> = []

    this.form.config.fields.forEach(field => {
      fields.push({
        type: field.type,
        name: field.name,
        value: this.getAnswerValue(answers.values, field)
      })
    })

    return fields
  }
}
