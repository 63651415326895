import { Component } from 'vue-property-decorator'
import Vue from 'vue'
import isMobile from 'piramis-js-utils/lib/isMobile'

@Component
export default class DrawerWidthMixin extends Vue {
    windowWidth = window.innerWidth

    private initWidth = 600

    initializeSidebarWidth(width:number):void {
        this.initWidth = width
    }

    get drawerWidth(): number | string {
        if (isMobile() || this.windowWidth <= 768 || this.windowWidth < this.initWidth) {
            return '100%'
        }

        return this.initWidth
    }

    handleWindowResize(event: any): void {
        this.windowWidth = event.currentTarget.innerWidth
    }

    mounted():void {
        window.addEventListener('resize', this.handleWindowResize, false)
    }

    destroyed() {
        removeEventListener('resize', this.handleWindowResize)
    }
}
